import AMapLoader from "@amap/amap-jsapi-loader";

export function initAMapLoader() {
  window._AMapSecurityConfig = {
    securityJsCode: "d8f56c47aa13eae1ad80c53a65f9467c",
  };
  return AMapLoader.load({
    key: "c66f5bbe01698059509349b00a96947a", // 申请好的Web端开发者Key，首次调用 load 时必填
    version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    plugins: ["AMap.MoveAnimation"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    AMapUI: {
      // 重点就是这个
      version: "1.1",
      plugins: ["misc/PathSimplifier", "overlay/SimpleMarker"], // SimpleMarker设置自定义图标，PathSimplifier轨迹展示组件
    },
  });
}

export function initAMap(AMap) {
  let map = new AMap.Map("map", {
    // 设置地图容器id
    pitch: 40, // 初始化倾斜角度
    viewMode: "3D",
    zoom: 10, // 初始化地图级别
    // mapStyle: "amap://styles/81fc183fc996fd17493b0ef90be2994f", // 初始化自定义样式
    mapStyle: "amap://styles/darkblue", // 初始化自定义样式
    // center: [121.7608, 41.36956], // 初始化地图中心点位置
    center: [92.795, 43.12016], // 初始化地图中心点位置
  });
  return map;
}

export const domainList = [
  ["93.27, 42.98", "93.18, 43.01", "93.09, 43.04"],
  ["93.09, 43.04", "92.88, 43.11", "92.83, 43.13", "92.8, 43.13"],
  [
    "92.8, 43.13",
    "92.75, 43.15",
    "92.74, 43.16",
    "92.72, 43.17",
    "92.69, 43.17",
    "92.64, 43.21",
  ],
  [
    "92.64, 43.21",
    "92.56, 43.25",
    "92.54, 43.26",
    "92.53, 43.26",
    "92.51, 43.25",
  ],
  [
    "92.51, 43.25",
    "92.48, 43.25",
    "92.39, 43.26",
    "92.35, 43.26",
    "92.33, 43.26",
    "92.32, 43.26",
  ],
];
export const markerPoints = [
  { type: 1, lngLat: domainList[0][0] },
  { type: 1, lngLat: domainList[0][2] },
  { type: 2, lngLat: domainList[1][1] },
  { type: 2, lngLat: domainList[1][2] },
  { type: 3, lngLat: domainList[2][2] },
  { type: 3, lngLat: domainList[2][4] },
  { type: 4, lngLat: domainList[3][0] },
  { type: 4, lngLat: domainList[3][3] },
  { type: 5, lngLat: domainList[4][2] },
  { type: 5, lngLat: domainList[4][4] },
];
