<template>
  <div class="info">
    <t-dialog
      width="1626px"
      top="8vh"
      :visible.sync="visible"
      :closeBtn="null"
      :cancelBtn="null"
      :confirmBtn="null"
    >
      <div class="title">
        <span>路段信息</span>
        <img class="bg" src="@/assets/page4/title-bg.png" alt="" />
        <img
          class="close"
          src="@/assets/page4/close.png"
          alt=""
          @click="visible = false"
        />
      </div>
      <div class="content">
        <div class="left">
          <div class="right-title">
            <img class="title-left" src="@/assets/page1/m3-title.png" alt="" />
            <span>路段详情</span>
            <img class="title-right" src="@/assets/page1/m3-title.png" alt="" />
            <img class="title-bottom" src="@/assets/page1/m3-b.png" alt="" />
          </div>
          <div class="line2">
            <div class="title">雷达信息</div>
            <div class="thead">
              <span>设备编号</span>
              <span>车速km/h</span>
              <span>超速</span>
              <span>车流量</span>
              <span>平均车速</span>
              <span>时间</span>
            </div>
            <div class="body">
              <span>001</span>
              <span>100</span>
              <span>是</span>
              <span>203</span>
              <span>100</span>
              <span>12:03:09</span>
            </div>
          </div>
          <div class="line3">
            <div class="tabs">
              <div class="tab">能见度</div>
              <div class="tab">风险等级</div>
              <button type="button" class="edit" @click="edit">编辑信息</button>
            </div>
            <div class="targets">
              <div class="target-item">能见度极高</div>
              <div class="target-item">能见度较高</div>
              <div class="target-item">能见度一般</div>
              <div class="target-item">能见度较低</div>
              <div class="target-item">能见度极低</div>
            </div>
          </div>
          <div class="line4">
            <div class="route">
              <div class="text-top">
                <div class="t-left">注意路滑</div>
                <div class="t-right">
                  <div>
                    道路千万条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;安全第一条
                  </div>
                  <div class="t-imgs">
                    <div><img src="@/assets/gobal/stop.png" alt="" /></div>
                    <div><span>120</span></div>
                    <div><img src="@/assets/page4/top.png" alt="" /></div>
                    <div><span>120</span></div>
                    <div><img src="@/assets/page4/top.png" alt="" /></div>
                    <div><span>120</span></div>
                    <div><img src="@/assets/page4/top.png" alt="" /></div>
                    <div><span>120</span></div>
                    <div><img src="@/assets/page4/top.png" alt="" /></div>
                  </div>
                </div>
              </div>
              <div class="text-bottom">
                <div class="t-item" style="background: #c5d7ec"></div>
                <div class="t-item" style="background: #06f700">
                  <span>2546</span> <img src="@/assets/page4/top.png" alt="" />
                </div>
                <div class="t-item" style="background: #06f700">
                  <span>2546</span> <img src="@/assets/page4/top.png" alt="" />
                </div>
                <div class="t-item" style="background: #28e3f2">
                  <span>2546</span> <img src="@/assets/page4/top.png" alt="" />
                </div>
                <div class="t-item" style="background: #28e3f2">
                  <span>2546</span> <img src="@/assets/page4/top.png" alt="" />
                </div>
              </div>
            </div>
            <div class="k">K451 +123</div>
            <div class="route">
              <div class="text-top">
                <div class="t-left">注意路滑</div>
                <div class="t-right">
                  <div>
                    道路千万条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;安全第一条
                  </div>
                  <div class="t-imgs">
                    <div><span>120</span></div>
                    <div><img src="@/assets/page4/top.png" alt="" /></div>
                    <div><span>120</span></div>
                    <div><img src="@/assets/page4/top.png" alt="" /></div>
                    <div><span>120</span></div>
                    <div><img src="@/assets/page4/top.png" alt="" /></div>
                    <div><span>120</span></div>
                    <div><img src="@/assets/page4/top.png" alt="" /></div>
                    <div><img src="@/assets/gobal/stop.png" alt="" /></div>
                  </div>
                </div>
              </div>
              <div class="text-bottom">
                <div class="t-item" style="background: #06f700">
                  <span>2546</span> <img src="@/assets/page4/top.png" alt="" />
                </div>
                <div class="t-item" style="background: #06f700">
                  <span>2546</span> <img src="@/assets/page4/top.png" alt="" />
                </div>
                <div class="t-item" style="background: #28e3f2">
                  <span>2546</span> <img src="@/assets/page4/top.png" alt="" />
                </div>
                <div class="t-item" style="background: #28e3f2">
                  <span>2546</span> <img src="@/assets/page4/top.png" alt="" />
                </div>
                <div class="t-item" style="background: #c5d7ec"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="right-title">
            <img class="title-left" src="@/assets/page1/m3-title.png" alt="" />
            <span>视频信息</span>
            <img class="title-right" src="@/assets/page1/m3-title.png" alt="" />
            <img class="title-bottom" src="@/assets/page1/m3-b.png" alt="" />
          </div>
          <div class="right-box">
            <div class="right-box-item"></div>
            <div class="right-box-item"></div>
          </div>
        </div>
      </div>
    </t-dialog>
  </div>
</template>

<script>
export default {
  name: "lane-info",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    edit() {
      this.$emit("edit");
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  ::v-deep .t-dialog {
    padding: 0;
    border: none;

    .t-dialog__header {
      display: none;
    }

    .t-dialog__body {
      padding: 0;
      background: rgba(#011020, 0.9);
      border: 1px solid rgba(8, 138, 213, 0.3);

      .title {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        position: relative;
        margin-top: 25px;

        .close {
          width: 46px;
          height: 46px;
          position: absolute;
          top: 0;
          right: 36px;
          cursor: pointer;
        }

        span {
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          line-height: 24px;
          background: linear-gradient(0deg, #a1e1ff 0%, #ffffff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .bg {
          width: 100%;
          height: 45px;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      .content {
        width: 100%;
        display: flex;
        padding: 28px 20px;

        .left {
          width: calc(100% - 580px);
          padding-right: 30px;
          border-right: 1px solid #0e4071;

          .right-title {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            //   background: rgba(#108cc6, 0.24);
            background-image: linear-gradient(
              to right,
              rgba(16, 140, 198, 0),
              rgba(16, 140, 198, 0.24),
              rgba(16, 140, 198, 0)
            );
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .title-left {
              width: 50px;
              height: 11px;
            }

            span {
              margin: 0 22px;
            }

            .title-right {
              width: 50px;
              height: 11px;
              transform: rotateY(180deg);
            }

            .title-bottom {
              width: 278px;
              height: 1.9px;
              position: absolute;
              bottom: 0;
              left: calc(50% - 139px);
            }
          }

          .line2 {
            width: 100%;
            margin-top: 24px;

            .title {
              width: 100%;
              height: 32px;
              line-height: 32px;
              text-align: center;
              background: rgba(#4799ff, 0.37);
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #fefefe;
            }

            .thead {
              width: 100%;
              display: flex;
              align-items: center;
              background: rgba(#143359, 0.54);
              span {
                width: 25%;
                height: 32px;
                line-height: 32px;
                text-align: center;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
              }
            }

            .body {
              width: 100%;
              display: flex;
              align-items: center;
              background: rgba(#143359, 0.32);
              span {
                width: 25%;
                height: 32px;
                line-height: 32px;
                text-align: center;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
          .line3 {
            width: 100%;
            margin-top: 30px;

            .tabs {
              width: 100%;
              display: flex;
              position: relative;

              .tab {
                width: 213px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background-image: url("@/assets/gobal/bg-choose.png");
                background-size: 100% 100%;
                margin-right: 20px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #24edff;
                cursor: pointer;
              }
              .edit {
                width: 100px;
                height: 32px;
                line-height: 30px;
                text-align: center;
                background: linear-gradient(0deg, #002040, #00326b);
                border: 1px solid #1c709e;
                border-radius: 4px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #ffffff;
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
              }
            }

            .targets {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              margin-top: 20px;

              .target-item {
                margin-left: 60px;
                position: relative;
              }

              .target-item:nth-of-type(1)::before {
                content: " ";
                width: 12px;
                height: 12px;
                background: #06f700;
                border-radius: 1px;
                position: absolute;
                left: -20px;
                top: 5px;
              }

              .target-item:nth-of-type(2)::before {
                content: " ";
                width: 12px;
                height: 12px;
                background: #00f7ba;
                border-radius: 1px;
                position: absolute;
                left: -20px;
                top: 5px;
                top: 5px;
              }

              .target-item:nth-of-type(3)::before {
                content: " ";
                width: 12px;
                height: 12px;
                background: #28e3f2;
                border-radius: 1px;
                position: absolute;
                left: -20px;
                top: 5px;
              }

              .target-item:nth-of-type(4)::before {
                content: " ";
                width: 12px;
                height: 12px;
                background: #f29128;
                border-radius: 1px;
                position: absolute;
                left: -20px;
                top: 5px;
              }

              .target-item:nth-of-type(5)::before {
                content: " ";
                width: 12px;
                height: 12px;
                background: #f22828;
                border-radius: 1px;
                position: absolute;
                left: -20px;
                top: 5px;
              }
            }
          }

          .line4 {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            .k {
              width: 48px;
              text-align: center;
              word-break: break-all;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              line-height: 24px;
            }
            .route {
              width: calc((100% - 48px) / 2);

              .text-top {
                width: 100%;
                display: flex;

                .t-left {
                  width: 48px;
                  height: 133px;
                  text-align: center;
                  line-height: 48px;
                  background: #17222f;
                  border: 1px solid #4f5c6c;
                  writing-mode: vertical-rl;
                  font-size: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #ee1616;
                }

                .t-right {
                  width: calc(100% - 48px);
                  height: 82px;
                  line-height: 82px;
                  text-align: center;
                  background: #17222f;
                  border: 1px solid #4f5c6c;
                  font-size: 18px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #ee1616;
                  border-left: none;

                  .t-imgs {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 2px;

                    > div {
                      width: calc(100% / 9);
                      height: 48px;
                      background: #17222f;
                      border: 1px solid #4f5c6c;
                      border-left: none;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      img {
                        width: 100%;
                        height: 100%;
                      }
                      span {
                        width: 44px;
                        height: 44px;
                        text-align: center;
                        line-height: 40px;
                        border: 2px solid #c80c0c;
                        border-radius: 50%;
                        font-size: 18px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #ffe42b;
                      }
                    }
                  }
                }
              }

              .text-bottom {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .t-item {
                  width: calc((100% - 4px) / 5);
                  height: 280px;
                  background: rgba(#c5d7ec, 0.32);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;

                  span {
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #ffffff;
                  }
                  img {
                    width: 48px;
                    height: 48px;
                    position: absolute;
                    bottom: 20px;
                    left: calc(50% - 24px);
                  }
                }
              }
            }
          }
        }

        .right {
          width: 580px;

          .right-title {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            //   background: rgba(#108cc6, 0.24);
            background-image: linear-gradient(
              to right,
              rgba(16, 140, 198, 0),
              rgba(16, 140, 198, 0.24),
              rgba(16, 140, 198, 0)
            );
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .title-left {
              width: 50px;
              height: 11px;
            }

            span {
              margin: 0 22px;
            }

            .title-right {
              width: 50px;
              height: 11px;
              transform: rotateY(180deg);
            }

            .title-bottom {
              width: 278px;
              height: 1.9px;
              position: absolute;
              bottom: 0;
              left: calc(50% - 139px);
            }
          }

          .right-box {
            width: 100%;
            padding: 25px 60px;

            .right-box-item {
              width: 100%;
              height: 340px;
              background: #010f1d;
              border: 1px solid #1787d7;
              margin-bottom: 30px;
            }
          }
        }
      }
    }

    .t-dialog__footer {
      display: none;
    }
  }
}
</style>
