<template>
  <div class="tabs-warpper">
    <div
      v-for="item in activeMap"
      :key="item.value"
      :class="{ tab: true, active: activeType === item.value }"
      @click="$emit('update:activeType', item.value)"
    >
      {{ item.label }}
    </div>
    <div class="risk-name">公路运行安全风险等级</div>
    <div class="level" v-for="(item, index) in riskLevelColors" :key="item">
      <span :style="{ backgroundColor: item }"></span> {{ index + 1 }}级
    </div>
  </div>
</template>

<script>
export default {
  name: "p4-tabs",
  props: {
    activeType: Number,
  },
  computed: {
    activeMap() {
      return [
        { label: "风 力", value: 1 },
        { label: "风 向", value: 2 },
      ];
    },
    riskLevelColors() {
      return ["#15E33C", "#15DDE9", "#F3E00F", "#F49D14", "#EB2727"];
    },
  },
};
</script>

<style lang="less" scoped>
.tabs-warpper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 20px 20px 0;

  .tab {
    width: 213px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-image: url("@/assets/gobal/bg-choose.png");
    background-size: 100% 100%;
    margin-right: 20px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #24edff;
    cursor: pointer;

    &.active {
      background-image: url("@/assets/gobal/bg-choosed.png");
    }
  }

  .risk-name {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 20px;
    margin-left: 200px;
  }

  .level {
    display: flex;
    align-items: center;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 20px;
    margin-left: 27px;

    span {
      width: 10px;
      height: 10px;
      margin-right: 10px;
    }
  }
}
</style>
