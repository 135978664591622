<template>
  <div class="ramp">
    <div class="fence top">
      <div class="line"></div>
      <div class="name">
        <img src="@/assets/page1/marker4.png" alt="" />
        <span>某某高速收费站</span>
      </div>
      <div class="left" @click="showInfo">
        <div class="lamp">
          <div style="color: #fb4d4d">60</div>
          <img src="@/assets/page4/lamp.png" alt="" />
        </div>
        <img src="@/assets/page4/rt.png" alt="" />
      </div>
      <div class="right" @click="showInfo">
        <div class="lamp">
          <img src="@/assets/page4/lamp.png" alt="" />
          <div style="color: #12ee65">60</div>
        </div>
        <img src="@/assets/page4/rt.png" alt="" />
      </div>
    </div>
    <div class="fence bottom">
      <div class="line"></div>
      <div class="name">
        <img src="@/assets/page1/marker4.png" alt="" />
        <span>某某高速收费站</span>
      </div>
      <div class="left" @click="showInfo">
        <img src="@/assets/page4/rt.png" alt="" />
        <div class="lamp">
          <div style="color: #FBED4D">60</div>
          <img src="@/assets/page4/lamp.png" alt="" />
        </div>
      </div>
      <div class="right" @click="showInfo">
        <img src="@/assets/page4/rt.png" alt="" />
        <div class="lamp">
          <img src="@/assets/page4/lamp.png" alt="" />
          <div style="color: #fb4d4d">60</div>
        </div>
      </div>
    </div>
    <info ref="info" @edit="showEdit" />
    <edit ref="edit" />
  </div>
</template>

<script>
import Info from "./components/info";
import Edit from "./components/edit";

export default {
  name: "c-ramp",
  components: { Info, Edit },
  methods: {
    showInfo() {
      this.$refs.info.show();
    },
    showEdit() {
      this.$refs.edit.show();
    },
  },
};
</script>

<style lang="less" scoped>
.ramp {
  .fence {
    width: 28px;
    height: 276px;
    background: #4f5c6c;
    border: 1px solid #5d6f85;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 51%;

    .line {
      width: 2px;
      height: 261px;
      background-color: #5d6f85;
    }

    .name {
      display: flex;
      align-items: center;
      position: absolute;
      top: 45%;
      left: -3px;

      img {
        width: 34px;
        margin-right: 4px;
      }

      span {
        font-family: SourceHanSansCN;
        font-weight: 300;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
        white-space: nowrap;
      }
    }

    .left,
    .right {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      position: absolute;

      .lamp {
        display: flex;
        align-items: center;
        padding: 0 10px;

        > div {
          width: 39px;
          height: 22px;
          background: #17222f;
          border: 1px solid #69819e;
          font-family: Impact;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
        }

        > img {
          width: 29px;
          height: 29px;
          position: relative;
        }
      }

      > img {
        width: 97px;
        height: 70px;
      }
    }

    .left {
      align-items: flex-start;
      right: 20px;

      .lamp {
        > div {
          padding-right: 7px;
        }

        > img {
          margin-left: -7px;
        }
      }
    }

    .right {
      align-items: flex-end;
      left: 20px;

      .lamp > div {
        margin-left: -7px;
        padding-left: 7px;
      }

      > img {
        transform: rotateY(180deg);
      }
    }

    &.top {
      align-items: flex-end;
      top: calc(50% - 156px - 276px);
    }

    &.bottom {
      align-items: flex-start;
      bottom: calc(50% - 156px - 276px);

      .left > img {
        transform: rotateX(180deg);
      }

      .right > img {
        transform: rotateX(180deg) rotateY(180deg);
      }
    }
  }
}
</style>
