<template>
  <div class="gantry-wrapper">
    <div class="gantry-box" v-for="item in gantryList" :key="item.topLeft">
      <div class="top" :style="{ left: item.topLeft }">
        <div class="gantry">
          <img src="@/assets/page4/frame_icon.png" alt="" />
          <div class="icons">
            <div v-for="device in 4" :key="device">
              <span v-if="device !== 4">{{ item.gantryNumber }}+1</span>
              <img :src="require(`@/assets/page4/e${device}.png`)" alt="" />
            </div>
          </div>
        </div>
        <img src="@/assets/page4/frame.png" alt="" />
      </div>
      <div class="bottom" :style="{ left: item.bottomLeft }">
        <img src="@/assets/page4/frame.png" alt="" />
        <div class="gantry">
          <img src="@/assets/page4/frame_icon.png" alt="" />
          <div class="icons">
            <div v-for="device in 4" :key="device">
              <span v-if="device !== 4">{{ item.gantryNumber }}+1</span>
              <img :src="require(`@/assets/page4/e${device}.png`)" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="device-box" v-for="item in deviceList" :key="item.topLeft">
      <div class="top icons" :style="{ left: item.topLeft }">
        <div v-for="device in 4" :key="device">
          <span v-if="device !== 4">{{ item.gantryNumber }}+1</span>
          <img :src="require(`@/assets/page4/e${device}.png`)" alt="" />
        </div>
      </div>
      <div class="bottom icons" :style="{ left: item.bottomLeft }">
        <div v-for="device in 4" :key="device">
          <span v-if="device !== 4">{{ item.gantryNumber }}+1</span>
          <img :src="require(`@/assets/page4/e${device}.png`)" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "c-gantry",
  data() {
    return {
      gantryList: [
        { gantryNumber: "K3036", topLeft: "12%", bottomLeft: "12.6%" },
        { gantryNumber: "K3066", topLeft: "34%", bottomLeft: "34.6%" },
        { gantryNumber: "K3136", topLeft: "82%", bottomLeft: "82.6%" },
      ],
      deviceList: [
        { gantryNumber: "K3016", topLeft: "2%", bottomLeft: "2%" },
        { gantryNumber: "K3046", topLeft: "24%", bottomLeft: "24%" },
        { gantryNumber: "K3116", topLeft: "68%", bottomLeft: "68%" },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.gantry-wrapper {
  .icons {
    display: flex;
    flex-direction: column;
    gap: 20px;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-family: PingFang SC;
        font-weight: 300;
        font-size: 12px;
        color: #ffffff;
        line-height: 20px;
        margin-bottom: 4px;
      }

      > img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .top,
  .bottom {
    position: absolute;
    z-index: 1;

    .gantry {
      display: flex;
      align-items: flex-end;

      > img {
        width: 56px;
        height: 141px;
        margin: 0 2px 8px 0;
      }
    }

    > img {
      width: 96px;
      height: 200px;
    }
  }

  .top {
    top: 4%;
    left: 12%;
  }

  .bottom {
    left: 12.6%;
    bottom: 12%;

    > img {
      transform: rotateY(180deg);
    }

    .gantry {
      align-items: flex-start;

      > img {
        transform: rotateY(180deg);
      }

      .icons {
        flex-direction: column-reverse;

        img {
          transform: rotateX(180deg);
        }
      }
    }
  }
}
</style>
