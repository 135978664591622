<template>
  <div class="module3" id="module3">
    <div class="content1">
      <div class="title">
        <span class="chinese">交通事件</span>
        <span class="english">Traffic incident</span>
      </div>
      <div class="box">
        <div class="box-item">
          <div class="box-item-title">
            <img class="left" src="@/assets/page1/m3-title.png" alt="" />
            <span>事件类型</span>
            <img class="right" src="@/assets/page1/m3-title.png" alt="" />
            <img class="bottom" src="@/assets/page1/m3-b.png" alt="" />
          </div>
          <dv-charts :option="option" style="width: 100%; height: 232px" />
        </div>
        <div class="box-item">
          <div class="box-item-title">
            <img class="left" src="@/assets/page1/m3-title.png" alt="" />
            <span>事件等级</span>
            <img class="right" src="@/assets/page1/m3-title.png" alt="" />
            <img class="bottom" src="@/assets/page1/m3-b.png" alt="" />
          </div>
          <div class="box-item-content2">
            <div class="box-item-content2-item">
              <div class="box-item-content2-item-left">
                <span class="number">12</span>
                <span class="name">一级事件</span>
              </div>
              <div class="box-item-content2-item-right">
                <div class="box-item-content2-item-right-item">
                  <span class="name">无需处理</span>
                  <div class="number">10</div>
                </div>
                <div class="box-item-content2-item-right-item">
                  <span class="name">未处理</span>
                  <div class="number">10</div>
                </div>
                <div class="box-item-content2-item-right-item">
                  <span class="name">已处理</span>
                  <div class="number">10</div>
                </div>
              </div>
            </div>
            <div class="box-item-content2-item">
              <div class="box-item-content2-item-left">
                <span class="number">12</span>
                <span class="name">三级事件</span>
              </div>
              <div class="box-item-content2-item-right">
                <div class="box-item-content2-item-right-item">
                  <span class="name">无需处理</span>
                  <div class="number">10</div>
                </div>
                <div class="box-item-content2-item-right-item">
                  <span class="name">未处理</span>
                  <div class="number">10</div>
                </div>
                <div class="box-item-content2-item-right-item">
                  <span class="name">已处理</span>
                  <div class="number">10</div>
                </div>
              </div>
            </div>
            <div class="box-item-content2-item">
              <div class="box-item-content2-item-left">
                <span class="number">12</span>
                <span class="name">二级事件</span>
              </div>
              <div class="box-item-content2-item-right">
                <div class="box-item-content2-item-right-item">
                  <span class="name">无需处理</span>
                  <div class="number">10</div>
                </div>
                <div class="box-item-content2-item-right-item">
                  <span class="name">未处理</span>
                  <div class="number">10</div>
                </div>
                <div class="box-item-content2-item-right-item">
                  <span class="name">已处理</span>
                  <div class="number">10</div>
                </div>
              </div>
            </div>
            <div class="box-item-content2-item">
              <div class="box-item-content2-item-left">
                <span class="number">12</span>
                <span class="name">四级事件</span>
              </div>
              <div class="box-item-content2-item-right">
                <div class="box-item-content2-item-right-item">
                  <span class="name">无需处理</span>
                  <div class="number">10</div>
                </div>
                <div class="box-item-content2-item-right-item">
                  <span class="name">未处理</span>
                  <div class="number">10</div>
                </div>
                <div class="box-item-content2-item-right-item">
                  <span class="name">已处理</span>
                  <div class="number">10</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-item">
          <div class="box-item-title">
            <img class="left" src="@/assets/page1/m3-title.png" alt="" />
            <span>实时事件</span>
            <img class="right" src="@/assets/page1/m3-title.png" alt="" />
            <img class="bottom" src="@/assets/page1/m3-b.png" alt="" />
          </div>
          <div class="box-item-content3">
            <div
              v-for="(item, index) in ['#0054A9', '#F78B00']"
              :key="item"
              class="box-item-content3-item"
            >
              <div class="level" :style="{ backgroundColor: item }">
                <span>事件等级{{ index + 3 }}</span>
              </div>
              <div class="box-item-content3-container">
                <div class="box-item-content3-title">
                  <div class="text">
                    <span class="text1">K0+123</span>
                    <span class="text2">常态事件</span>
                  </div>
                  <div class="time">2024.08.06 11:09:12</div>
                </div>
                <div class="box-item-content3-content">
                  <div class="info">
                    <div class="info1">
                      <span class="info1-text1">事件类型:****</span>
                      <span class="info1-text2">影响车道：2 哈蜜方向</span>
                    </div>
                    <div class="info2">影响范围：UP-460+000 ~ UP-470+000</div>
                  </div>
                  <img src="@/assets/page1/m3-r.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <border />
    <div class="flexible" id="flexible3" @click="handleFlexible">
      <img src="@/assets/gobal/flexible.png" alt="" />
    </div>
  </div>
</template>

<script>
import Border from "@/components/Border";
export default {
  name: "p1-m3",
  components: { Border },
  data() {
    const option = {
      xAxis: {
        name: "",
        data: ["类型一", "类型二", "类型三", "类型四", "类型五", "类型六"],
        axisLine: {
          style: {
            stroke: "#306269",
            lineWidth: 1,
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          style: {
            fill: "#FFFFFF",
            fontSize: 12,
          },
        },
      },
      yAxis: {
        name: "",
        data: "value",
        // interval: 50,
        splitNumber: 5,
        axisLine: {
          show: false,
        },
        axisLabel: {
          style: {
            fill: "#FFFFFF",
            fontSize: 12,
          },
        },
        splitLine: {
          style: {
            stroke: "rgba(48, 98, 105, 0.2)",
          },
        },
      },
      series: [
        {
          data: [50, 100, 150, 100, 200, 250],
          type: "bar",
          gradient: {
            color: ["rgba(60,193,255,0.2)"],
          },
          barStyle: {
            stroke: "#3CC1FF",
          },
          barWidth: 15,
        },
      ],
      grid: {
        top: 40,
        bottom: 40,
        left: 30,
        right: 30,
      },
    };
    return {
      show: true,
      option: option,
    };
  },
  methods: {
    handleFlexible() {
      let module = document.getElementById("module3");
      let flexible = document.getElementById("flexible3");
      this.show
        ? module.setAttribute("style", "bottom:-332px")
        : module.setAttribute("style", "bottom:20px");
      this.show
        ? flexible.setAttribute("style", "transform: rotate(90deg)")
        : flexible.setAttribute("style", "transform: rotate(-90deg)");
      this.show = !this.show;
    },
  },
};
</script>

<style lang="less" scoped>
.module3 {
  width: 1402px;
  height: 332px;
  padding: 20px 25px 0 25px;
  background: rgba(#393c40, 0.4);
  position: absolute;
  bottom: 20px;
  right: 20px;
  transition: all 0.5s;

  .content1 {
    width: 100%;
    height: 100%;
    .title {
      width: 100%;
      height: 25px;
      background-image: url("@/assets/gobal/title-bg1.png");
      background-size: 100% 100%;
      margin-bottom: 20px;

      .chinese {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        margin-left: 31px;
        position: relative;
        top: -1px;
      }

      .english {
        font-size: 12px;
        font-family: Artifakt Element;
        font-weight: bold;
        color: rgba(#dcf4ff, 0.4);
        margin-top: 6px;
        margin-left: 6px;
      }
    }
    .box {
      width: 100%;
      height: calc(100% - 50px);
      display: flex;
      align-items: center;

      .box-item:nth-of-type(1) {
        width: 444px;
      }

      .box-item:nth-of-type(2) {
        width: 468px;
      }
      .box-item:nth-of-type(3) {
        width: calc(100% - 912px);
      }
      .box-item {
        height: 100%;
        border-right: 1px solid rgba(16, 140, 198, 0.3);

        .box-item-title {
          width: 100%;
          height: 30px;
          line-height: 30px;
          text-align: center;
          //   background: rgba(#108cc6, 0.24);
          background-image: linear-gradient(
            to right,
            rgba(16, 140, 198, 0),
            rgba(16, 140, 198, 0.24),
            rgba(16, 140, 198, 0)
          );
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .left {
            width: 50px;
            height: 11px;
          }

          span {
            margin: 0 22px;
          }

          .right {
            width: 50px;
            height: 11px;
            transform: rotateY(180deg);
          }

          .bottom {
            width: 278px;
            height: 1.9px;
            position: absolute;
            bottom: 0;
            left: calc(50% - 139px);
          }
        }

        .box-item-content2 {
          width: 100%;
          padding: 20px 15px;
          height: calc(100% - 30px);
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          justify-content: space-between;

          .box-item-content2-item {
            width: 210px;
            height: 86px;
            background-image: url("@/assets/page1/m3-bg2.png");
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 24px 15px 10px;

            .box-item-content2-item-left {
              width: 66px;
              padding-right: 10px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              .number {
                font-size: 28px;
                font-family: DIN Alternate;
                font-weight: bold;
                margin-bottom: 10px;
              }
              .name {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
              }
            }

            .box-item-content2-item-right {
              width: calc(100% - 66px);
              height: 100%;
              border-left: 1px solid rgba(16, 140, 198, 0.3);
              display: flex;
              align-content: space-between;
              justify-content: center;
              flex-wrap: wrap;

              .box-item-content2-item-right-item {
                width: 100%;
                padding-left: 21px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;

                .name {
                  font-size: 12px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #ffffff;
                }

                .number {
                  font-size: 18px;
                  font-family: DIN Alternate;
                  font-weight: bold;
                }
              }

              .box-item-content2-item-right-item:nth-of-type(1) {
                .number {
                  color: #06f700;
                }
              }
              .box-item-content2-item-right-item:nth-of-type(1)::before {
                content: " ";
                width: 6px;
                height: 6px;
                background: #06f700;
                border-radius: 50%;
                position: absolute;
                top: 7px;
                left: 10px;
              }
              .box-item-content2-item-right-item:nth-of-type(2)::before {
                content: " ";
                width: 6px;
                height: 6px;
                background: #24edff;
                border-radius: 50%;
                position: absolute;
                top: 7px;
                left: 10px;
              }
              .box-item-content2-item-right-item:nth-of-type(3)::before {
                content: " ";
                width: 6px;
                height: 6px;
                background: #ff2af2;
                border-radius: 50%;
                position: absolute;
                top: 7px;
                left: 10px;
              }

              .box-item-content2-item-right-item:nth-of-type(2) {
                .number {
                  color: #24edff;
                }
              }
              .box-item-content2-item-right-item:nth-of-type(3) {
                .number {
                  color: #ff2af2;
                }
              }
            }
          }
          .box-item-content2-item:nth-of-type(1) {
            .box-item-content2-item-left {
              .number {
                color: #ff2525;
              }
            }
          }
          .box-item-content2-item:nth-of-type(2) {
            .box-item-content2-item-left {
              .number {
                color: #f7cb00;
              }
            }
          }
          .box-item-content2-item:nth-of-type(3) {
            .box-item-content2-item-left {
              .number {
                color: #f78b00;
              }
            }
          }
          .box-item-content2-item:nth-of-type(4) {
            .box-item-content2-item-left {
              .number {
                color: #1388ff;
              }
            }
          }
        }

        .box-item-content3 {
          width: 100%;
          height: calc(100% - 30px);
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          padding: 7px 0 9px 17px;

          .box-item-content3-item {
            width: 100%;
            height: 104px;
            display: flex;

            .level {
              width: 25px;
              text-align: center;
              height: 100%;
              background: #0054a9;
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #ffffff;
              word-wrap: break-word;
              display: flex;
              align-items: center;
              justify-content: center;
              span {
                display: inline-block;
                width: 12px;
                line-height: 14px;
              }
            }

            .box-item-content3-container {
              width: calc(100% - 25px);
              height: 100%;
              .box-item-content3-title {
                width: 100%;
                height: 32px;
                padding: 0 8px 0 13px;
                background: rgba(#1388ff, 0.2);
                display: flex;
                align-items: center;
                justify-content: space-between;

                .text {
                  display: flex;
                  align-items: center;

                  .text1 {
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #fefefe;
                    margin-right: 15px;
                  }

                  .text2 {
                    width: 64px;
                    height: 22px;
                    line-height: 20px;
                    text-align: center;
                    background: #1a3659;
                    border: 1px solid #06f700;
                    border-radius: 11px;
                    font-size: 12px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #06f700;
                  }
                }

                .time {
                  font-size: 12px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #ffffff;
                }
              }

              .box-item-content3-content {
                width: 100%;
                height: calc(100% - 32px);
                padding: 0 12px;
                background: rgba(#143359, 0.54);
                display: flex;
                align-items: center;
                justify-content: space-between;

                .info {
                  width: calc(100% - 100px);
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;

                  .info1 {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .info1-text1 {
                      display: inline-block;
                      width: 50%;
                      font-size: 12px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #ffffff;
                    }
                    .info1-text2 {
                      display: inline-block;
                      width: 50%;
                      font-size: 12px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #ffffff;
                    }
                  }

                  .info2 {
                    width: 100%;
                    font-size: 12px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #ffffff;
                    margin-top: 14px;
                  }
                }

                img {
                  width: 66px;
                  height: 66px;
                }
              }
            }
          }
        }
      }
      .box-item:last-child {
        border-right: 1px solid transparent;
      }
    }
  }

  .flexible {
    width: 44px;
    height: 70px;
    position: absolute;
    top: -57px;
    left: calc(50% - 35px);
    transform: rotate(-90deg);

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 0px;
    background: rgba(6, 114, 199, 0.3);
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 0px;
    background: rgba(6, 114, 199, 0.2);
  }

  ::-webkit-scrollbar-corner {
    background: rgba(6, 114, 199, 0.2);
  }
}
</style>
