import Vue from "vue";
import Router from "vue-router";
import Page1 from "@/views/Page1";
import Page4 from "@/views/Page4";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/page-1",
      name: "Page1",
      component: Page1,
    },
    {
      path: "/page-4",
      name: "Page4",
      component: Page4,
    },
    {
      path: "**",
      redirect: "/page-1",
      hidden: true,
    },
  ],
});
