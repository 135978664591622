<template>
  <div class="container" id="container">
    <navigation-bar />
    <tabs :activeType.sync="activeType" />
    <p>哈密市伊州区哈密北出口 —— 吐鲁番市鄯善县吐峪沟互通方向</p>
    <div class="control">
      <ramp />
      <gantry />
      <lane />
    </div>
    <p>吐鲁番市鄯善县吐峪沟互通 —— 哈密市伊州区哈密北出口方向</p>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import Tabs from "./compontnts/tabs";
import Ramp from "./compontnts/ramp";
import Gantry from "./compontnts/gantry";
import Lane from "./compontnts/lane";

export default {
  name: "page-4",
  components: { NavigationBar, Tabs, Ramp, Gantry, Lane },
  data() {
    return {
      activeType: 1,
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;

  .control {
    width: 100%;
    height: calc(100% - 62px - 60px * 2 - 52px);
    position: relative;
  }

  > p {
    font-family: PingFang SC;
    font-weight: 300;
    font-size: 12px;
    color: #d3d3d1;
    line-height: 14px;
    margin: 12px 0 0 20px;
  }
}
</style>
