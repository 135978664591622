<template>
  <div class="module2" id="module2">
    <div class="content1">
      <div class="title">
        <span class="chinese">风险路段预警</span>
        <span class="english">Section warning</span>
      </div>
      <div class="table">
        <table>
          <thead>
            <tr>
              <th
                v-for="(item, index) in config.header"
                :key="index"
                v-html="item"
                :style="{ width: config.columnWidth[index] + 'px' }"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in config.data"
              :style="{
                background:
                  index % 2 !== 0 ? 'transparent' : 'rgba(6, 114, 199, 0.2)',
              }"
              :key="index"
            >
              <td
                v-for="(el, num) in item"
                :key="num"
                v-html="el"
                :style="{ width: config.columnWidth[index] + 'px' }"
              ></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="content2">
      <div class="title">
        <span class="chinese">智能诱导</span>
        <span class="english">Intelligent guidance</span>
      </div>
      <div class="box1">
        <div v-for="item in 6" :key="item" class="box1-item">
          <div class="box1-item-title">
            <span class="name">情报板K0+123</span>
            <span class="time">2024-08-06 11:09:12</span>
          </div>
          <div class="box1-item-content">
            <div v-if="item % 2 !== 0" class="box1-item-content-text">
              前方大型事故，请提前选择行车方向
            </div>
            <div v-else class="box1-item-content-flex">
              <div
                v-for="el in 5"
                :key="el"
                class="box1-item-content-flex-item"
              >
                <div v-if="el < 5" class="number">100</div>
                <div v-else class="icon"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="box1-item"></div>
        <div class="box1-item"></div>
        <div class="box1-item"></div>
      </div>
    </div>
    <border />
    <div class="flexible" id="flexible2" @click="handleFlexible">
      <img src="@/assets/gobal/flexible.png" alt="" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Border from "@/components/Border";
export default {
  name: "p1-m2",
  components: { Border },
  data() {
    return {
      show: true,
      config: {
        columnWidth: [80, 80, 80, 80],
        header: ["预计发生时间", "风险路段", "等级", "类型", "风险指数预测值"],
        data: [
          [
            "09:11:12",
            "名称",
            '<span calss="type">低风险</span>',
            "拥堵",
            `${Math.floor(Math.random() * 100)}`,
          ],
          [
            "09:11:12",
            "名称",
            '<span calss="type">低风险</span>',
            "拥堵",
            `${Math.floor(Math.random() * 100)}`,
          ],
          [
            "09:11:12",
            "名称",
            '<span calss="type">低风险</span>',
            "拥堵",
            `${Math.floor(Math.random() * 100)}`,
          ],
          [
            "09:11:12",
            "名称",
            '<span calss="type">低风险</span>',
            "拥堵",
            `${Math.floor(Math.random() * 100)}`,
          ],
          [
            "09:11:12",
            "名称",
            '<span calss="type">低风险</span>',
            "拥堵",
            `${Math.floor(Math.random() * 100)}`,
          ],
          [
            "09:11:12",
            "名称",
            '<span calss="type">低风险</span>',
            "拥堵",
            `${Math.floor(Math.random() * 100)}`,
          ],
          [
            "09:11:12",
            "名称",
            '<span calss="type">低风险</span>',
            "拥堵",
            `${Math.floor(Math.random() * 100)}`,
          ],
        ],
      },
    };
  },
  mounted() {},
  methods: {
    handleFlexible() {
      let module = document.getElementById("module2");
      let flexible = document.getElementById("flexible2");
      this.show
        ? module.setAttribute("style", "right:-460px")
        : module.setAttribute("style", "right:20px");
      this.show
        ? flexible.setAttribute("style", "transform: rotate(0deg)")
        : flexible.setAttribute("style", "transform: rotate(180deg)");
      this.show = !this.show;
    },
  },
};
</script>

<style lang="less" scoped>
.module2 {
  width: 460px;
  height: 770px;
  padding: 20px 10px;
  background: rgba(#393c40, 0.4);
  position: absolute;
  top: 20px;
  right: 20px;
  transition: all 0.5s;

  .content1 {
    width: 100%;
    margin-bottom: 20px;

    .title {
      width: 100%;
      height: 30px;
      background-image: url("@/assets/gobal/title-bg.png");
      background-size: 100% 100%;
      margin-bottom: 5px;

      .chinese {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        margin-left: 31px;
        position: relative;
        top: -1px;
      }

      .english {
        font-size: 12px;
        font-family: Artifakt Element;
        font-weight: bold;
        color: rgba(#dcf4ff, 0.4);
        margin-top: 6px;
        margin-left: 6px;
      }
    }

    .table {
      width: 100%;
      height: 254px;
      overflow-x: auto;
      overflow-y: auto;

      table {
        width: 100%;
        position: relative;
        table-layout: fixed;
        border-collapse: collapse;

        thead {
          width: 100%;
          tr {
            width: 100%;
            padding: 0;
            height: 40px;
            line-height: 40px;
            th {
              text-align: center;
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: rgba(#d8f2ff, 0.6);
            }
          }
        }

        tbody {
          width: 100%;
          tr {
            height: 32px;
            line-height: 32px;
            padding: 0;
            td {
              text-align: center;
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              text-overflow: ellipsis;
              word-wrap: break-word;
            }
          }
        }
      }
    }
  }

  .content2 {
    width: 100%;
    margin-bottom: 30px;

    .title {
      width: 100%;
      height: 30px;
      background-image: url("@/assets/gobal/title-bg.png");
      background-size: 100% 100%;
      margin-bottom: 20px;

      .chinese {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        margin-left: 31px;
        position: relative;
        top: -1px;
      }

      .english {
        font-size: 12px;
        font-family: Artifakt Element;
        font-weight: bold;
        color: rgba(#dcf4ff, 0.4);
        margin-top: 6px;
        margin-left: 6px;
      }
    }

    .box1 {
      width: 100%;
      height: 386px;
      overflow-y: auto;

      .box1-item {
        width: 100%;
        margin-bottom: 10px;
        .box1-item-title {
          width: 100%;
          padding: 8px 15px;
          background: rgba(#1388ff, 0.2);
          display: flex;
          align-items: center;
          justify-content: space-between;

          .name {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #fefefe;
          }

          .time {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .box1-item-content {
          width: 100%;
          padding: 12px 15px;
          background: rgba(#143359, 0.54);
          .box1-item-content-text {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }

          .box1-item-content-flex {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .number {
              width: 52px;
              height: 52px;
              line-height: 48px;
              text-align: center;
              border: 2px solid #e92426;
              border-radius: 50%;
              font-size: 18px;
              font-family: Impact;
              font-weight: 400;
              color: #f7cb00;
            }

            .icon {
              width: 38px;
              height: 38px;
              background: #e92426;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }

  .flexible {
    width: 44px;
    height: 70px;
    position: absolute;
    left: -44px;
    top: calc(50% - 35px);
    transform: rotate(180deg);

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 0px;
    background: rgba(6, 114, 199, 0.3);
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 0px;
    background: rgba(6, 114, 199, 0.2);
  }

  ::-webkit-scrollbar-corner {
    background: rgba(6, 114, 199, 0.2);
  }
}
</style>
