<template>
  <div class="map" id="map" v-cloak></div>
</template>

<script>
import {
  initAMapLoader,
  initAMap,
  domainList,
  markerPoints,
} from "@/utils/map";

const defualtColor = "#35E4FF";

export default {
  name: "a-map",
  props: {
    markerType: Number,
    colors: Array,
    showRiskLevel: Boolean,
  },
  data() {
    return {
      AMap: null,
      map: null,
      markers: [],
      polylines: [],
    };
  },
  computed: {
    markerIcons() {
      const props = {
        size: new this.AMap.Size(36, 36),
        imageSize: new this.AMap.Size(36, 36),
      };
      return {
        1: { image: require(`@/assets/page1/marker1.png`), ...props },
        2: { image: require(`@/assets/page1/marker2.png`), ...props },
        3: { image: require(`@/assets/page1/marker3.png`), ...props },
        4: { image: require(`@/assets/page1/marker4.png`), ...props },
        5: { image: require(`@/assets/page1/marker5.png`), ...props },
      };
    },
  },
  watch: {
    markerType(value) {
      this.renderMarkers(value);
    },
    showRiskLevel() {
      this.resetPolylineOptions();
    },
  },
  async created() {
    this.AMap = await initAMapLoader();
    this.map = initAMap(this.AMap);
    this.renderPolylines();
    this.renderMarkers();
  },
  methods: {
    renderPolylines() {
      this.clearPolylines();

      domainList.forEach((item, index) => {
        const levelColor = this.colors[index % this.colors.length];
        const points = item.map((v) => v.split(",").map((v) => +v));
        const polyline = new this.AMap.Polyline({
          path: points, // 设置线覆盖物路径
          strokeColor: this.showRiskLevel ? levelColor : defualtColor, // 线颜色
          strokeOpacity: 1, // 线透明度
          strokeWeight: 8, // 线宽
          strokeStyle: "solid", // 线样式
          extData: { levelColor },
        });
        this.polylines.push(polyline);
        this.map.add(polyline);
      });
    },
    resetPolylineOptions() {
      this.polylines.forEach((polyline) => {
        const { levelColor } = polyline.getExtData();
        const color = this.showRiskLevel ? levelColor : defualtColor;
        polyline.setOptions({ strokeColor: color });
      });
    },
    clearPolylines() {
      if (!this.map || !this.polylines.length) return;

      this.map.remove(this.polylines);
      this.polylines = [];
    },

    renderMarkers(type) {
      this.clearMarkers();

      const data = type
        ? markerPoints.filter((item) => item.type === type)
        : markerPoints;

      data.forEach(({ lngLat, type }) => {
        const marker = new this.AMap.Marker({
          position: lngLat.split(","),
          icon: new this.AMap.Icon(this.markerIcons[type]),
          offset: new this.AMap.Pixel(-36 / 2, -36),
          map: this.map,
        });
        this.markers.push(marker);
      });
    },
    clearMarkers() {
      if (!this.map || !this.markers.length) return;

      this.map.remove(this.markers);
      this.markers = [];
    },
  },
};
</script>

<style lang="less" scoped>
.map {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
