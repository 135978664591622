<template>
  <div class="route">
    <div class="route-item" v-for="(item, index) in routeLineList" :key="index">
      <div class="line top" @click="showInfo">
        <div class="title">应急车道</div>
        <div class="figure" :style="{ background: item.color[0] }">
          限速：120
        </div>
        <div class="figure" :style="{ background: item.color[1] }">
          限速：100
        </div>
      </div>
      <div class="line bottom" @click="showInfo">
        <div class="figure" :style="{ background: item.color[2] }">
          限速：100
        </div>
        <div class="figure" :style="{ background: item.color[3] }">
          限速：120
        </div>
        <div class="title">应急车道</div>
      </div>
    </div>
    <div class="pile-number">
      <div class="center" v-for="item in 15" :key="item">
        K{{ 3006 + item * 10 }}
      </div>
    </div>
    <info ref="info" @edit="showEdit" />
    <edit ref="edit" />
  </div>
</template>

<script>
import Info from "./components/info";
import Edit from "./components/edit";

export default {
  name: "c-lane",
  components: { Info, Edit },
  data() {
    return {
      routeLineList: [
        {
          color: [
            "rgba(21, 227, 60, 0.7)",
            "rgba(21, 221, 233, 0.7)",
            "rgba(21, 227, 60, 0.7)",
            "rgba(21, 221, 233, 0.7)",
          ],
        },
        {
          color: [
            "rgba(21, 227, 60, 0.7)",
            "rgba(21, 221, 233, 0.7)",
            "rgba(21, 227, 60, 0.7)",
            "rgba(21, 221, 233, 0.7)",
          ],
        },
        {
          color: [
            "rgba(243, 224, 15, 0.7)",
            "rgba(21, 221, 233, 0.7)",
            "rgba(243, 224, 15, 0.7)",
            "rgba(21, 221, 233, 0.7)",
          ],
        },
        {
          color: [
            "rgba(21, 227, 60, 0.7)",
            "rgba(21, 221, 233, 0.7)",
            "rgba(21, 227, 60, 0.7)",
            "rgba(21, 221, 233, 0.7)",
          ],
        },
        {
          color: [
            "rgba(21, 227, 60, 0.7)",
            "rgba(244, 157, 20, 0.7)",
            "rgba(21, 227, 60, 0.7)",
            "rgba(244, 157, 20, 0.7)",
          ],
        },
        {
          color: [
            "rgba(21, 227, 60, 0.7)",
            "rgba(21, 221, 233, 0.7)",
            "rgba(21, 227, 60, 0.7)",
            "rgba(21, 221, 233, 0.7)",
          ],
        },
      ],
    };
  },
  methods: {
    showInfo() {
      this.$refs.info.show();
    },
    showEdit() {
      this.$refs.edit.show();
    },
  },
};
</script>

<style lang="less" scoped>
.route {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .route-item {
    flex: 1;

    .line {
      cursor: pointer;

      .title {
        height: 30px;
        text-align: center;
        background: rgba(197, 215, 236, 0.32);
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        line-height: 30px;
      }

      .figure {
        height: 38px;
        text-align: center;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        line-height: 38px;
      }

      &.top {
        .title {
          border-bottom: 1px solid #fff;
        }

        .figure:last-child {
          border-top: 1px dashed #fff;
          border-bottom: 1px solid #fff;
        }
      }

      &.bottom {
        margin-top: 100px;

        .figure:first-child {
          border-top: 1px solid #fff;
          border-bottom: 1px dashed #fff;
        }

        .title {
          border-top: 1px solid #fff;
        }
      }
    }

    .center {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      > div {
        display: flex;
        align-items: center;

        span {
          text-align: center;
        }
      }
    }
  }

  .pile-number {
    width: 100%;
    display: flex;
    position: absolute;
    top: calc(50% - 17px);
    left: 0;

    > div {
      flex: 1;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      font-size: 14px;
      color: #ffffff;
      line-height: 34px;
      padding: 0 26px;
      position: relative;

      &::before {
        content: "";
        width: 1px;
        height: 100%;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 20px;
      }
    }
  }
}
</style>
