<template>
  <div class="edit">
    <t-dialog
      width="920px"
      top="8vh"
      :visible.sync="visible"
      :closeBtn="null"
      :cancelBtn="null"
      :confirmBtn="null"
    >
      <div class="title">
        <span>匝道编辑</span>
        <img class="bg" src="@/assets/page4/title-bg.png" alt="" />
        <img
          class="close"
          src="@/assets/page4/close.png"
          alt=""
          @click="visible = false"
        />
      </div>
      <div class="content">
        <div class="item">
          <div class="right-title">
            <img class="title-left" src="@/assets/page1/m3-title.png" alt="" />
            <span>视频信息</span>
            <img class="title-right" src="@/assets/page1/m3-title.png" alt="" />
            <img class="title-bottom" src="@/assets/page1/m3-b.png" alt="" />
          </div>
          <div class="form">
            <div class="form-item">
              <span>信号灯设置（编号）：</span>
              <div class="form-item-input">
                <span>红灯</span>
                <input type="text" value="60" />
                <span>秒</span>
              </div>
              <div class="form-item-input">
                <span>绿灯</span>
                <input type="text" value="60" />
                <span>秒</span>
              </div>
              <div class="form-item-input">
                <span>黄灯</span>
                <input type="text" value="60" />
                <span>秒</span>
              </div>
            </div>
            <div class="form-item">
              <span>信号灯设置（编号）：</span>
              <div class="form-item-input">
                <span>红灯</span>
                <input type="text" value="60" />
                <span>秒</span>
              </div>
              <div class="form-item-input">
                <span>绿灯</span>
                <input type="text" value="60" />
                <span>秒</span>
              </div>
              <div class="form-item-input">
                <span>黄灯</span>
                <input type="text" value="60" />
                <span>秒</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="right-title">
            <img class="title-left" src="@/assets/page1/m3-title.png" alt="" />
            <span>第一车道</span>
            <img class="title-right" src="@/assets/page1/m3-title.png" alt="" />
            <img class="title-bottom" src="@/assets/page1/m3-b.png" alt="" />
          </div>
          <div class="form">
            <div class="form-item">
              <span>收费类型：</span>
              <input type="radio" name="t1-1" checked />
              <label for="t1-1">ETC专用</label>

              <input type="radio" name="t2-1" />
              <label for="t2-1">人工车道</label>

              <input type="radio" name="t3-1" />
              <label for="t3-1">ETC/人工</label>

              <input type="radio" name="t4-1" />
              <label for="t4-1">无</label>
            </div>
            <div class="form-item" style="width: 50%">
              <div class="form-item-input">
                <span>车道限速：</span>
                <input type="text" value="60" />
                <span>km/h</span>
              </div>
            </div>
            <div class="form-item" style="width: 50%">
              <span>车道是否允许通行：</span>
              <input type="radio" name="t1" checked />
              <label for="t1">允许通行</label>

              <input type="radio" name="t2" />
              <label for="t2">禁止通行</label>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="right-title">
            <img class="title-left" src="@/assets/page1/m3-title.png" alt="" />
            <span>第二车道</span>
            <img class="title-right" src="@/assets/page1/m3-title.png" alt="" />
            <img class="title-bottom" src="@/assets/page1/m3-b.png" alt="" />
          </div>
          <div class="form">
            <div class="form-item">
              <span>收费类型：</span>
              <input type="radio" name="t1-2" checked />
              <label for="t1-2">ETC专用</label>

              <input type="radio" name="t2-2" />
              <label for="t2-2">人工车道</label>

              <input type="radio" name="t3-2" />
              <label for="t3-2">ETC/人工</label>

              <input type="radio" name="t4-2" />
              <label for="t4-2">无</label>
            </div>
            <div class="form-item" style="width: 50%">
              <div class="form-item-input">
                <span>车道限速：</span>
                <input type="text" value="60" />
                <span>km/h</span>
              </div>
            </div>
            <div class="form-item" style="width: 50%">
              <span>车道是否允许通行：</span>
              <input type="radio" name="t1-2-1" checked />
              <label for="t1-2-1">允许通行</label>

              <input type="radio" name="t2-2-1" />
              <label for="t2-2-1">禁止通行</label>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="right-title">
            <img class="title-left" src="@/assets/page1/m3-title.png" alt="" />
            <span>第三车道</span>
            <img class="title-right" src="@/assets/page1/m3-title.png" alt="" />
            <img class="title-bottom" src="@/assets/page1/m3-b.png" alt="" />
          </div>
          <div class="form">
            <div class="form-item">
              <span>收费类型：</span>
              <input type="radio" name="t1-3" checked />
              <label for="t1-3">ETC专用</label>

              <input type="radio" name="t2-3" />
              <label for="t2-3">人工车道</label>

              <input type="radio" name="t3-3" />
              <label for="t3-3">ETC/人工</label>

              <input type="radio" name="t4-3" />
              <label for="t4-3">无</label>
            </div>
            <div class="form-item" style="width: 50%">
              <div class="form-item-input">
                <span>车道限速：</span>
                <input type="number" value="60" />
                <span>km/h</span>
              </div>
            </div>
            <div class="form-item" style="width: 50%">
              <span>车道是否允许通行：</span>
              <input type="radio" name="t1-3-1" checked />
              <label for="t1-3-1">允许通行</label>

              <input type="radio" name="t2-3-1" />
              <label for="t2-3-1">禁止通行</label>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="right-title">
            <img class="title-left" src="@/assets/page1/m3-title.png" alt="" />
            <span>第四车道</span>
            <img class="title-right" src="@/assets/page1/m3-title.png" alt="" />
            <img class="title-bottom" src="@/assets/page1/m3-b.png" alt="" />
          </div>
          <div class="form">
            <div class="form-item">
              <span>收费类型：</span>
              <input type="radio" name="t1-4" checked />
              <label for="t1-4">ETC专用</label>

              <input type="radio" name="t2-4" />
              <label for="t2-4">人工车道</label>

              <input type="radio" name="t3-4" />
              <label for="t3-4">ETC/人工</label>

              <input type="radio" name="t4-4" />
              <label for="t4-4">无</label>
            </div>
            <div class="form-item" style="width: 50%">
              <div class="form-item-input">
                <span>车道限速：</span>
                <input type="text" value="60" />
                <span>km/h</span>
              </div>
            </div>
            <div class="form-item" style="width: 50%">
              <span>车道是否允许通行：</span>
              <input type="radio" name="t1-4-1" checked />
              <label for="t1-4-1">允许通行</label>

              <input type="radio" name="t2-4-1" />
              <label for="t2-4-1">禁止通行</label>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="right-title">
            <img class="title-left" src="@/assets/page1/m3-title.png" alt="" />
            <span>电子屏信息</span>
            <img class="title-right" src="@/assets/page1/m3-title.png" alt="" />
            <img class="title-bottom" src="@/assets/page1/m3-b.png" alt="" />
          </div>
          <div class="form">
            <div class="form-item">
              <div class="form-item-input" style="align-items: flex-start">
                <span style="width: 80px">条形屏：</span>
                <textarea name="t1" v-html="`道路千万条，安全第一条`" />
              </div>
            </div>
            <div class="form-item">
              <div class="form-item-input" style="align-items: flex-start">
                <span style="width: 80px"></span>
                <textarea name="t1" v-html="`注意安全`"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </t-dialog>
  </div>
</template>

<script>
export default {
  name: "ramp-edit",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.edit {
  ::v-deep .t-dialog {
    padding: 0;
    border: none;

    .t-dialog__header {
      display: none;
    }

    .t-dialog__body {
      padding: 24px 27px;
      padding-top: 0;
      background: rgba(#011020, 0.9);
      border: 1px solid rgba(8, 138, 213, 0.3);

      .title {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        position: relative;
        margin-top: 25px;
        margin-bottom: 24px;

        .close {
          width: 46px;
          height: 46px;
          position: absolute;
          top: 0;
          right: 36px;
          cursor: pointer;
        }

        span {
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          line-height: 24px;
          background: linear-gradient(0deg, #a1e1ff 0%, #ffffff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .bg {
          width: 100%;
          height: 45px;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      .content {
        width: 100%;

        .item {
          width: 100%;
          margin-bottom: 12px;

          .right-title {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            //   background: rgba(#108cc6, 0.24);
            background-image: linear-gradient(
              to right,
              rgba(16, 140, 198, 0),
              rgba(16, 140, 198, 0.24),
              rgba(16, 140, 198, 0)
            );
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .title-left {
              width: 50px;
              height: 11px;
            }

            span {
              margin: 0 22px;
            }

            .title-right {
              width: 50px;
              height: 11px;
              transform: rotateY(180deg);
            }

            .title-bottom {
              width: 278px;
              height: 1.9px;
              position: absolute;
              bottom: 0;
              left: calc(50% - 139px);
            }
          }

          .form {
            width: 100%;
            padding: 20px;
            padding-bottom: 10px;
            display: flex;
            flex-wrap: wrap;

            .form-item {
              width: 100%;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #ffffff;
              display: flex;
              align-items: center;
              margin: 10px 0;

              label {
                margin-left: 10px;
                margin-right: 32px;
              }

              .form-item-input {
                display: flex;
                align-items: center;
                margin-right: 50px;

                input,
                textarea {
                  width: 100px;
                  height: 32px;
                  line-height: 32px;
                  background: #0b1c2a;
                  border: 1px solid #1c709e;
                  border-radius: 4px;
                  text-align: center;
                  outline: none;
                  font-size: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  color: #ffffff;
                  margin: 0 10px;
                }

                textarea {
                  min-width: 600px;
                  max-width: 600px;
                  min-height: 70px;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }

    .t-dialog__footer {
      display: none;
    }
  }
}
</style>
