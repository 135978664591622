<template>
  <div class="info">
    <t-dialog
      width="1626px"
      top="8vh"
      :visible.sync="visible"
      :closeBtn="null"
      :cancelBtn="null"
      :confirmBtn="null"
    >
      <div class="title">
        <span>匝道信息</span>
        <img class="bg" src="@/assets/page4/title-bg.png" alt="" />
        <img
          class="close"
          src="@/assets/page4/close.png"
          alt=""
          @click="visible = false"
        />
      </div>
      <div class="content">
        <div class="left">
          <div class="line1">
            <div class="lamp">
              <span class="lamp-name">交通信号灯（K1+1）：</span>
              <div class="lamp-box">
                <img src="@/assets/page4/lamp.png" alt="" />
                <span>60</span>
              </div>
            </div>
            <div class="lamp">
              <span class="lamp-name">交通信号灯（K2+1）：</span>
              <div class="lamp-box">
                <img src="@/assets/page4/lamp.png" alt="" />
                <span>60</span>
              </div>
            </div>
            <button type="button" class="edit" @click="edit">编辑信息</button>
          </div>
          <div class="line2">
            <div class="title">信息列表</div>
            <div class="thead">
              <span>车流量</span>
              <span>车道</span>
              <span>车速km/h</span>
              <span>时间</span>
            </div>
            <div class="body">
              <span>1290</span>
              <span>1</span>
              <span>18</span>
              <span>12:09:12</span>
            </div>
          </div>
          <div class="line3">
            <div class="title">某某某收费站</div>
            <div class="s1">
              道路千万条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;安全第一条
            </div>
            <div class="s2">
              <div v-for="item in 8" :key="item" class="item">
                <div v-if="item % 2 == 0" style="color: #ff2424">
                  <span>禁止通行</span>
                  <img src="@/assets/page4/no.png" alt="" />
                  <div>
                    <span v-if="item !== 1 || item !== 7 || item !== 8"
                      >20</span
                    >
                    <img src="@/assets/page4/top.png" alt="" />
                  </div>
                </div>
                <div v-if="item % 2 != 0" style="color: #24ff2e">
                  <span>ETC专用</span>
                  <img src="@/assets/page4/yes.png" alt="" />
                  <div>
                    <img src="@/assets/page4/top.png" alt="" />
                  </div>
                </div>
                <span class="p1" v-if="item === 2">安全第一 谨慎驾驶</span>
                <span class="p2" v-if="item === 6">安全第一 谨慎驾驶</span>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="right-title">
            <img class="title-left" src="@/assets/page1/m3-title.png" alt="" />
            <span>视频信息</span>
            <img class="title-right" src="@/assets/page1/m3-title.png" alt="" />
            <img class="title-bottom" src="@/assets/page1/m3-b.png" alt="" />
          </div>
          <div class="right-box">
            <div class="right-box-item"></div>
            <div class="right-box-item"></div>
          </div>
        </div>
      </div>
    </t-dialog>
  </div>
</template>

<script>
export default {
  name: "ramp-info",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    edit() {
      this.$emit("edit");
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  ::v-deep .t-dialog {
    padding: 0;
    border: none;

    .t-dialog__header {
      display: none;
    }

    .t-dialog__body {
      padding: 0;
      background: rgba(#011020, 0.9);
      border: 1px solid rgba(8, 138, 213, 0.3);

      .title {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        position: relative;
        margin-top: 25px;

        .close {
          width: 46px;
          height: 46px;
          position: absolute;
          top: 0;
          right: 36px;
          cursor: pointer;
        }

        span {
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          line-height: 24px;
          background: linear-gradient(0deg, #a1e1ff 0%, #ffffff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .bg {
          width: 100%;
          height: 45px;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      .content {
        width: 100%;
        display: flex;
        padding: 28px 20px;

        .left {
          width: calc(100% - 580px);
          padding-right: 30px;

          .line1 {
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;

            .lamp {
              display: flex;
              align-items: center;
              margin-right: 90px;

              .lamp-name {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
              }

              .lamp-box {
                width: 156px;
                height: 52px;
                line-height: 52px;
                background: #0b1c2a;
                border: 1px solid #4f5c6c;
                border-radius: 100px 0 0 100px;
                display: flex;
                align-items: center;
                img {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                }

                span {
                  width: calc(100% - 52px);
                  text-align: center;
                  font-size: 36px;
                  font-family: DIN Alternate;
                  font-weight: bold;
                  color: #23ef27;
                }
              }
            }

            .edit {
              width: 100px;
              height: 32px;
              line-height: 30px;
              text-align: center;
              background: linear-gradient(0deg, #002040, #00326b);
              border: 1px solid #1c709e;
              border-radius: 4px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #ffffff;
              position: absolute;
              top: 0;
              right: 0;
              cursor: pointer;
            }
          }
          .line2 {
            width: 100%;
            margin-top: 24px;

            .title {
              width: 100%;
              height: 32px;
              line-height: 32px;
              text-align: center;
              background: rgba(#4799ff, 0.37);
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #fefefe;
            }

            .thead {
              width: 100%;
              display: flex;
              align-items: center;
              background: rgba(#143359, 0.54);
              span {
                width: 25%;
                height: 32px;
                line-height: 32px;
                text-align: center;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
              }
            }

            .body {
              width: 100%;
              display: flex;
              align-items: center;
              background: rgba(#143359, 0.32);
              span {
                width: 25%;
                height: 32px;
                line-height: 32px;
                text-align: center;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
          .line3 {
            width: 100%;
            margin-top: 30px;

            .title {
              width: 100%;
              height: 50px;
              line-height: 50px;
              text-align: center;
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #ffffff;
              background: #1b4270;
            }

            .s1 {
              width: 100%;
              height: 48px;
              line-height: 48px;
              text-align: center;
              background: #17222f;
              border: 1px solid #4f5c6c;
              font-size: 29px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #e92426;
              margin-top: 7px;
            }

            .s2 {
              width: 100%;
              margin-top: 9px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .item {
                width: calc((100% - 7px) / 8);
                position: relative;

                > div {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;

                  > span {
                    width: 100%;
                    height: 34px;
                    line-height: 34px;
                    text-align: center;
                    background: #17222f;
                    border: 1px solid #4f5c6c;
                    font-size: 22px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                  }
                  > img {
                    width: 48px;
                    height: 48px;
                    margin-top: 10px;
                  }
                  > div {
                    width: 100%;
                    height: 350px;
                    background: rgba(#c5d7ec, 0.32);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 11px;
                    > span {
                      width: 36px;
                      height: 36px;
                      line-height: 36px;
                      text-align: center;
                      border-radius: 50%;
                      border: 1px solid #e92426;
                      font-size: 20px;
                      color: #fff;
                      margin-bottom: 33px;
                    }
                    > img {
                      width: 28px;
                      height: 37px;
                      margin-bottom: 20px;
                    }
                  }
                }

                .p1,
                .p2 {
                  width: 95px;
                  height: 62px;
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #24ff2e;
                  background: #0b1c2a;
                  border: 1px solid #4f5c6c;
                  text-align: center;
                  word-break: break-all;
                  position: absolute;
                  top: 103px;
                  right: -48px;
                  padding: 14px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  z-index: 99;
                }
              }
            }
          }
        }

        .right {
          width: 580px;

          .right-title {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            //   background: rgba(#108cc6, 0.24);
            background-image: linear-gradient(
              to right,
              rgba(16, 140, 198, 0),
              rgba(16, 140, 198, 0.24),
              rgba(16, 140, 198, 0)
            );
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .title-left {
              width: 50px;
              height: 11px;
            }

            span {
              margin: 0 22px;
            }

            .title-right {
              width: 50px;
              height: 11px;
              transform: rotateY(180deg);
            }

            .title-bottom {
              width: 278px;
              height: 1.9px;
              position: absolute;
              bottom: 0;
              left: calc(50% - 139px);
            }
          }

          .right-box {
            width: 100%;
            padding: 25px 60px;

            .right-box-item {
              width: 100%;
              height: 340px;
              background: #010f1d;
              border: 1px solid #1787d7;
              margin-bottom: 30px;
            }
          }
        }
      }
    }

    .t-dialog__footer {
      display: none;
    }
  }
}
</style>
