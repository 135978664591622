<template>
  <div class="module" id="module1">
    <div class="content1">
      <div class="title">
        <span class="chinese">运行概览</span>
        <span class="english">Operation overview</span>
      </div>
      <div class="box1">
        <div class="box1-item">
          <div class="box1-item-left">
            <img src="@/assets/page1/box1-icon1.png" alt="" />
          </div>
          <div class="box1-item-right">
            <dv-digital-flop class="number" :config="box1Config[0]" />
            <div class="name">雷达设备数</div>
          </div>
        </div>
        <div class="box1-item">
          <div class="box1-item-left">
            <img src="@/assets/page1/box1-icon2.png" alt="" />
          </div>
          <div class="box1-item-right">
            <dv-digital-flop class="number" :config="box1Config[1]" />
            <div class="name">摄像头数</div>
          </div>
        </div>
        <div class="box1-item">
          <div class="box1-item-left">
            <img src="@/assets/page1/box1-icon3.png" alt="" />
          </div>
          <div class="box1-item-right">
            <dv-digital-flop class="number" :config="box1Config[2]" />
            <div class="name">情报板数</div>
          </div>
        </div>
        <div class="box1-item">
          <div class="box1-item-left">
            <img src="@/assets/page1/box1-icon4.png" alt="" />
          </div>
          <div class="box1-item-right">
            <dv-digital-flop class="number" :config="box1Config[3]" />
            <div class="name">收费站数</div>
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="box2-item">
          <dv-digital-flop class="number" :config="box2Config[0]" />
          <div class="name">今日交通流量(辆)</div>
        </div>
        <div class="box2-item">
          <dv-digital-flop class="number" :config="box2Config[1]" />
          <div class="name">今日事件量(件)</div>
        </div>
        <div class="box2-item">
          <dv-digital-flop class="number" :config="box2Config[2]" />
          <div class="name">平均速度(km/h)</div>
        </div>
      </div>
      <div class="box3">
        <div class="box3-content">
          <div class="box3-content-left">
            <dv-digital-flop class="number" :config="box3Config[0]" />
            <div class="name">收费站出入口流量(辆)</div>
          </div>
          <div class="box3-content-right">
            <div class="box3-content-right-item">
              <span class="name">收费站入口流量(辆)</span>
              <dv-digital-flop class="number" :config="box3Config[1]" />
            </div>
            <div class="box3-content-right-item">
              <span class="name">收费站出口流量(辆)</span>
              <dv-digital-flop class="number" :config="box3Config[2]" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="title">
        <span class="chinese">路段行车风险情况</span>
        <span class="english" style="letter-spacing: -1.2px">
          Risk situation of driving on road sections
        </span>
      </div>
      <div class="box4">
        <div
          v-for="item in ['#F3E00F', '#15E33C', '#108CC6']"
          :key="item"
          class="box4-item"
        >
          <div class="box4-item-title">
            <i :style="{ backgroundColor: item }"></i>
            <span class="box4-item-title-left">某某路段名称</span>
            <div class="box4-item-title-right">
              <span>2024.08.06 11:09:12</span>
              <img src="@/assets/page1/box4-jump.png" alt="" />
            </div>
          </div>
          <div class="box4-item-content">
            <div class="box4-item-content-item">风力：18m/s</div>
            <div class="box4-item-content-item">风向：东南</div>
            <div class="box4-item-content-item">行车安全风险：3级</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="title">
        <span class="chinese">重点监控路段</span>
        <span class="english">Key monitoring sections</span>
      </div>
      <div class="box5">
        <div v-for="item in 4" :key="item" class="box5-item">
          <div class="box5-item-title">桩号</div>
          <img :src="require(`@/assets/video/video${item}.jpg`)" alt="" />
          <!-- <video :src="url" autoplay="autoplay" :controls="false" muted>
            您的浏览器不支持视频播放
          </video> -->
        </div>
      </div>
    </div>
    <border />
    <div class="flexible" id="flexible1" @click="handleFlexible">
      <img src="@/assets/gobal/flexible.png" alt="" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Border from "@/components/Border";

const box1ConfigStyle = {
  fontSize: 26,
  fontWeight: "bold",
  fill: "#24EDFF",
};
const box2ConfigStyle = {
  fontSize: 28,
  fontWeight: "bold",
  fill: "#f7cb00",
};
const box3ConfigStyle = {
  fontWeight: "bold",
  fill: "#f7cb00",
};

export default {
  name: "p1-m1",
  components: { Border },
  data() {
    return {
      url: "https://oss-www-ranmc-com.oss-cn-shenzhen.aliyuncs.com/2021-12-21%2018-07-21.mp4",
      box1Config: [
        { number: [356], textAlign: "left", style: box1ConfigStyle },
        { number: [233], textAlign: "left", style: box1ConfigStyle },
        { number: [125], textAlign: "left", style: box1ConfigStyle },
        { number: [24], textAlign: "left", style: box1ConfigStyle },
      ],
      box2Config: [
        { number: [4725], style: box2ConfigStyle },
        { number: [24], style: box2ConfigStyle },
        { number: [108], style: box2ConfigStyle },
      ],
      box3Config: [
        {
          number: [5649],
          style: { ...box3ConfigStyle, fontSize: 28 },
        },
        {
          number: [3425],
          textAlign: "right",
          style: { ...box3ConfigStyle, fontSize: 20 },
        },
        {
          number: [2224],
          textAlign: "right",
          style: { ...box3ConfigStyle, fontSize: 20 },
        },
      ],
      show: true,
      originalPoints: [],
    };
  },
  mounted() {},
  methods: {
    handleFlexible() {
      let module = document.getElementById("module1");
      let flexible = document.getElementById("flexible1");
      this.show
        ? module.setAttribute("style", "left:-460px")
        : module.setAttribute("style", "left:20px");
      this.show
        ? flexible.setAttribute("style", "transform: rotate(180deg)")
        : flexible.setAttribute("style", "transform: rotate(0deg)");
      this.show = !this.show;
    },
  },
};
</script>

<style lang="less" scoped>
.module {
  width: 460px;
  padding: 20px 10px;
  background: rgba(#393c40, 0.4);
  position: absolute;
  top: 20px;
  left: 20px;
  bottom: 20px;
  transition: all 0.5s;

  .content1 {
    width: 100%;
    margin-bottom: 20px;

    .title {
      width: 100%;
      height: 30px;
      background-image: url("@/assets/gobal/title-bg.png");
      background-size: 100% 100%;
      margin-bottom: 5px;

      .chinese {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        margin-left: 31px;
        position: relative;
        top: -1px;
      }

      .english {
        font-size: 12px;
        font-family: Artifakt Element;
        font-weight: bold;
        color: rgba(#dcf4ff, 0.4);
        margin-top: 6px;
        margin-left: 6px;
      }
    }

    .box1 {
      width: 100%;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .box1-item {
        width: 190px;
        height: 60px;
        margin: 10px 0;
        background-image: url("@/assets/page1/box1-bg.png");
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        padding-left: 40px;

        .box1-item-left {
          width: 30px;
          height: 30px;
          margin-right: 15px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .box1-item-right {
          .number {
            width: auto;
            height: 26px;
          }

          .name {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #b2d8ff;
          }
        }
      }
    }

    .box2 {
      width: 100%;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .box2-item {
        width: 120px;
        height: 69px;
        background-image: url("@/assets/page1/box2-bg.png");
        background-size: 100% 100%;

        .number {
          width: 100%;
          height: calc(100% - 26px);
          padding-top: 6px;
        }

        .name {
          width: 100%;
          height: 26px;
          line-height: 26px;
          text-align: center;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          background: rgba(247, 203, 0, 0.3);
        }
      }
    }
    .box3 {
      width: 100%;
      padding: 10px 15px;

      .box3-content {
        width: 100%;
        height: 70px;
        padding: 10px 0 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-image: url("@/assets/page1/box3-bg.png");
        background-size: 100% 100%;

        .box3-content-left {
          width: 150px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: space-between;

          .number {
            width: 100%;
            height: 28px;
            line-height: 1.5;
            text-align: center;
          }

          .name {
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .box3-content-right {
          width: calc(100% - 150px);
          height: 100%;
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: space-between;

          .box3-content-right-item {
            width: 100%;
            padding: 0 17px 0 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            .name {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
            }

            .number {
              flex: 1;
              height: 20px;
              line-height: 1.5;
            }
          }
          .box3-content-right-item::before {
            content: " ";
            width: 7px;
            height: 7px;
            background: #f7cb00;
            border-radius: 50%;
            position: absolute;
            left: 20px;
            top: 6px;
          }
        }
      }
    }
  }

  .content2 {
    width: 100%;
    margin-bottom: 30px;
    .title {
      width: 100%;
      height: 30px;
      background-image: url("@/assets/gobal/title-bg.png");
      background-size: 100% 100%;

      .chinese {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        margin-left: 31px;
        position: relative;
        top: -1px;
      }

      .english {
        font-size: 12px;
        font-family: Artifakt Element;
        font-weight: bold;
        color: rgba(#dcf4ff, 0.4);
        margin-top: 6px;
        margin-left: 6px;
      }
    }
    .box4 {
      width: 100%;
      .box4-item {
        width: 100%;
        margin: 10px 0;
        .box4-item-title {
          width: 100%;
          height: 32px;
          padding: 0 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: rgba(#4799ff, 0.2);
          cursor: pointer;
          position: relative;
          > i {
            width: 4px;
            height: 32px;
            background: #f3e00f;
            position: absolute;
            top: 0;
            left: 0;
          }
          .box4-item-title-left {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #fefefe;
          }
          .box4-item-title-right {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            span {
              margin-right: 12px;
            }
            img {
              width: 6px;
              height: 12px;
            }
          }
        }
        .box4-item-content {
          width: 100%;
          padding: 5px 15px;
          background: rgba(#143359, 0.54);
          display: flex;
          flex-wrap: wrap;
          .box4-item-content-item {
            position: relative;
            width: 50%;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            padding: 5px 15px;
          }

          .box4-item-content-item::before {
            content: " ";
            position: absolute;
            top: 8px;
            left: 2px;
            width: 7px;
            height: 7px;
            background: #24edff;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .content3 {
    width: 100%;
    margin-bottom: 20px;
    .title {
      width: 100%;
      height: 30px;
      background-image: url("@/assets/gobal/title-bg.png");
      background-size: 100% 100%;

      .chinese {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        margin-left: 31px;
        position: relative;
        top: -1px;
      }

      .english {
        font-size: 12px;
        font-family: Artifakt Element;
        font-weight: bold;
        color: rgba(#dcf4ff, 0.4);
        margin-top: 6px;
        margin-left: 6px;
      }
    }

    .box5 {
      width: 100%;
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .box5-item {
        width: 200px;
        height: 120px;
        border: 1px solid #1787d7;
        margin: 10px 0;
        position: relative;

        .box5-item-title {
          width: 100%;
          height: 20px;
          line-height: 1.5;
          padding-left: 14px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          background: rgba(#001427, 0.5);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 9999;
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover !important;
        }
      }
    }
  }

  .flexible {
    width: 44px;
    height: 70px;
    position: absolute;
    right: -44px;
    top: calc(50% - 35px);

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}
</style>
