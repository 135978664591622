<template>
  <div class="container">
    <navigation-bar />
    <div class="content">
      <a-map
        :colors="riskLevelColors"
        :marker-type="markerType"
        :show-risk-level="showRiskLevel"
      />
      <module1>
        <div class="map-handle">
          <div class="search">
            <input name="name" autocomplete="off" placeholder="路段名称" />
            <img src="@/assets/page1/search.png" alt="" />
          </div>
          <div class="button-group">
            <div
              :class="{ 'button-item': true, active: markerType === 0 }"
              @click="markerType = 0"
            >
              <span>全部</span>
            </div>
            <div
              :class="{ 'button-item': true, active: markerType === 1 }"
              @click="markerType = 1"
            >
              <img src="@/assets/page1/m-s1.png" alt="" />
              <span>雷达</span>
            </div>
            <div
              :class="{ 'button-item': true, active: markerType === 2 }"
              @click="markerType = 2"
            >
              <img src="@/assets/page1/m-s2.png" alt="" />
              <span>施工</span>
            </div>
            <div
              :class="{ 'button-item': true, active: markerType === 3 }"
              @click="markerType = 3"
            >
              <img src="@/assets/page1/m-s3.png" alt="" />
              <span>摄像头</span>
            </div>
            <div
              :class="{ 'button-item': true, active: markerType === 4 }"
              @click="markerType = 4"
            >
              <img src="@/assets/page1/m-s4.png" alt="" />
              <span>收费站</span>
            </div>
            <div
              :class="{ 'button-item': true, active: markerType === 5 }"
              @click="markerType = 5"
            >
              <img src="@/assets/page1/m-s5.png" alt="" />
              <span>情报板</span>
            </div>
          </div>
        </div>
      </module1>
      <module2>
        <div class="risk-level-wrapper">
          <div class="switch-box">
            <div
              :class="{ switch: true, active: showRiskLevel }"
              @click="showRiskLevel = !showRiskLevel"
            ></div>
            <span>风险等级</span>
          </div>
          <div v-if="showRiskLevel" class="example-box">
            <div v-for="(item, index) in riskLevelColors" :key="item">
              <span class="color" :style="{ backgroundColor: item }"></span>
              <span class="label">{{ index + 1 }}级</span>
            </div>
          </div>
        </div>
      </module2>
      <module3 />
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import AMap from "./components/map";
import Module1 from "./components/module1";
import module2 from "./components/module2";
import Module3 from "./components/module3";

export default {
  name: "page-1",
  components: {
    NavigationBar,
    AMap,
    Module1,
    module2,
    Module3,
  },
  data() {
    return {
      markerType: 0,
      showRiskLevel: true,
    };
  },
  computed: {
    riskLevelColors() {
      return ["#15E33C", "#15DDE9", "#F3E00F", "#F49D14", "#EB2727"];
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .content {
    width: 100%;
    height: calc(100% - 62px);
    padding: 20px;
    position: relative;
  }

  .map-handle {
    width: 400px;
    position: absolute;
    top: 0;
    right: -420px;

    .search {
      width: 100%;
      position: relative;
      input {
        width: 100%;
        height: 32px;
        padding: 0 30px 0 15px;
        line-height: 32px;
        background: linear-gradient(0deg, #002040, #00326b);
        border: 1px solid #1c709e;
        border-radius: 4px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }

      input::placeholder {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }

      input:focus {
        outline: none;
      }

      img {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .button-group {
      width: 100%;

      .button-item {
        width: 214px;
        height: 42px;
        background-image: url("@/assets/gobal/bg-choose.png");
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        cursor: pointer;

        img {
          width: 26px;
          height: 26px;
          margin-right: 10px;
        }
        span {
          width: 48px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #24edff;
          text-align: justify;
          text-align-last: justify;
        }

        &.active {
          background-image: url("@/assets/gobal/bg-choosed.png");
        }
      }
    }
  }

  .risk-level-wrapper {
    width: 120px;
    position: absolute;
    top: 0;
    left: -140px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .switch-box {
      width: 120px;
      height: 32px;
      background: linear-gradient(0deg, #002040, #00326b);
      border-radius: 4px;
      border: 1px solid #1c709e;
      display: flex;
      justify-content: center;
      align-items: center;

      .switch {
        width: 28px;
        height: 10px;
        background: #d8f2ff;
        border-radius: 5px;
        position: relative;
        margin-right: 10px;
        cursor: pointer;

        &.active {
          background: #35e4ff;

          &::before {
            left: calc(100% - 14px);
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: -2px;
          left: 0;
          width: 14px;
          height: 14px;
          background: #fdfdfd;
          box-shadow: 0 0 4px 0 #044b5f;
          border-radius: 50%;
          transition: left 0.2s;
        }
      }

      > span {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #fdfdfd;
        line-height: 20px;
      }
    }

    .example-box {
      width: 104px;
      height: 130px;
      background: rgba(4, 75, 95, 0.7);
      border-radius: 4px;
      border: 1px solid rgba(4, 75, 95, 0.7);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 2px 0;
      margin-top: 10px;

      > div {
        display: flex;
        align-items: center;
        padding-left: 30px;

        .color {
          width: 10px;
          height: 10px;
          margin-right: 10px;
        }

        .label {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
